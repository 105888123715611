import "./App.scss";
import { About, Footer, Header, Skill } from "./container";
import { Navbar } from "./components";
import Work from "./container/Works/Work";

function App() {
  return (
    <div className="app">
      <Navbar />
      <Header />
      <About />
      <Work />
      <Skill />
      <Footer />
    </div>
  );
}

export default App;
